// 采购计划
import request from '@/utils/request'
// import { get } from 'core-js/core/dict'

// 查询（分页）
export function getlistPage(params) {
    return request({
       url: "/api/PurchasePlan/listPage",
       params,
       method: "get"
    })
}

// 查询初始化
export function getPageInit() {
    return request({
       url: "/api/PurchasePlan/pageInit",
       method: "get"  
    })
}

// 商品查询 (分页)
export function getProductListPage(params) {
    return request({
      url: "/api/PurchasePlan/productListPage",
      params,
      method: "get" 
    })
}  

// 新增
export function addPurchasePlan(data) {
    return request({
        url: "/api/PurchasePlan",
       data,
       method: "post"
    })
}

// 单个删除
export function deletePurchasePlan(id) {
    return request({
      url: "/api/PurchasePlan/" + id,
      method: "delete"
    })
}

// 单个提交审核
export function getBatchUpdate(data) {
    return request({
       url: "/api/PurchasePlan/batchUpdate",
       data,
       method: "put"
    })
}

// 查看详情
export function getMoreDetail(id) {
   return request({
     url: "/api/PurchasePlan/" + id,
     method: "get"
   })
}

// 编辑初始化
export function getUpdateInit(id) {
    return request({
      url: "/api/PurchasePlan/updateInit/" + id,
      method: "get"
    })
}

// 编辑数据
export function editPurchasePlan(data) {
   return request({
       url: "/api/PurchasePlan",
       data,
       method: "put"
   })
}

// 生成采购单按钮
export function addPurchaseInit(ids) {
    return request({
      url: "/api/PurchasePlan/addPurchaseInit/" + ids,
      // params,
      method: "get"
    })
}

// 生成采购单-创建采购计划按钮
export function purchasePlanCreateList(params) {
   return request({
     url: "/api/PurchasePlan/purchasePlanCreateList",
     params,
     method: "get"
   })
}

// 批量删除
export function batchDeletePlan(data) {
   return request ({
    url: "/api/PurchasePlan/batchDelete",
    data,
    method: "delete"
   })
}

//修改备注
export function updataTips(data) {
  return request({
    method:'put',
    url:`/api/PurchasePlan/updateTips`,
    data
  });
}